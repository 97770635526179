// TODO: move calendar strain tiles into separate component
import React from 'react'
import { Link } from 'gatsby'

import usePagination from '../../hooks/usePagination'
import useMedia from '../../hooks/useMedia'
import { Button } from 'components/Button'
import { PaginationButton } from 'components/Collection/PaginationButton'
import { column, monthTile, emptyTile, heading } from './Calendar.module.scss'
import '../StrainCollection/StrainCollection.scss'
import '../StrainCard/StrainCard.scss'
import { themes } from '../../global'

export const Calendar = ({ strainCalendar }) => {
  const paginatedDates = usePagination(strainCalendar, 6)

  const columnCount = useMedia(
    // Media queries
    [
      '(min-width: 1600px)',
      '(min-width: 1212px)',
      '(min-width: 580px)',
      '(min-width: 340px)',
      '(min-width: 1px)',
    ],
    // Column counts (relates to above media queries by array index)
    [6, 6, 3, 1, 1],
    // Default column count
    6
  )

  const handlePrevious = (e) => {
    e.preventDefault()
    paginatedDates.prev()
  }

  const handleNext = (e) => {
    e.preventDefault()
    paginatedDates.next()
  }

  return (
    <div className='iliad__strain-collection--section'>
      <div className='iliad__strain-collection--header'>
        <div>
          <div>
            <h2>Calendar</h2>
            <p>
              Iliad Epic Grow is proud to present the following Strain Harvest
              schedule:
            </p>
            <div className='iliad__strain-collection--controls'>
              <PaginationButton
                variant='prev'
                disabled={paginatedDates.currentPage <= 1}
                onClick={handlePrevious}
                aria-label='Click to navigate to the previous page'
              />

              {/* used non-breaking space characters to save development time */}
              <span id='page-indicators'>
                <span className='iliad__display--no-mobile'>Page&nbsp;</span>
                <span>{paginatedDates.currentPage} /&nbsp;</span>
                {paginatedDates.totalPages}
              </span>
              <PaginationButton
                disabled={
                  paginatedDates.currentPage >= paginatedDates.totalPages
                }
                onClick={handleNext}
                aria-label='Click to navigate to the next page'
              />
            </div>
          </div>
        </div>
        <div className='iliad__strain-collection--wrapper animation-strain-collection'>
          <div className='iliad__strain-collection--container'>
            <div className='iliad__strain-collection-subgrid'>
              {paginatedDates.currentData().map(({ month, strains = [] }) => {
                return (
                  <div
                    key={month}
                    style={{ gridColumnEnd: `span ${12 / columnCount}` }}>
                    <h3 className={heading}>{month}</h3>

                    {strains.length >= 1 ? (
                      <div className={column}>
                        {strains
                          .filter((strain) => Boolean(strain))
                          .map((strain) => (
                            <Link
                              key={strain.name}
                              className={monthTile}
                              to={`/strains${strain.slug}`}>
                              {strain.name} &#8250;
                            </Link>
                          ))}
                      </div>
                    ) : (
                      <div className={column}>
                        <div className={`${monthTile} ${emptyTile}`}>
                          Upcoming strains to be announced
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            <Button
              buttonAction={'scrollNext'}
              nextSection={'#page-section-0'}
              buttonText='Current Harvest'
              colorTheme={themes['white']}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
